(function(APP, window) {
  'use strict';

  /**
   * Projects Sample class
   * @param {Object} options
   */
  function ProjectsSample(options) {
    if (!(this instanceof ProjectsSample)) return new ProjectsSample(options);

    this.config = {
      modalClass: 'js-projects-sample-modal',
      changeModalClass: 'js-projects-sample-modal-change',
      activeModalClass: 'js-projects-sample-modal-active',
      closeModalClass: 'js-projects-sample-modal-close',
      triggerClass: 'js-projects-sample-trigger',
      bodyClass: 'js-projects-sample-body',
      nameClass: 'js-projects-sample-name',
      typeClass: 'js-projects-sample-type',
      excerptClass: 'js-projects-sample-excerpt',
      keywordsClass: 'js-projects-sample-keywords',
      linkClass: 'js-projects-sample-link',
      nextTriggerClass: 'js-projects-sample-next-trigger',
      nextNameClass: 'js-projects-sample-next-name',
      transitionDuration: 300,
      transitionDelay: 200,
      endOfTransitions: 300,
    };

    if (APP.is.desktop === false) {
      return false;
    }

    this.modalElements = {
      modal: options.container.querySelector('.' + this.config.modalClass),
    };

    if (
      this.modalElements.close === null ||
      (window.projectsSample === undefined || window.projectsSample === '')
    ) {
      return false;
    }

    this.modalElements = Object.assign(this.modalElements, {
      body: options.container.querySelector('.' + this.config.bodyClass),
      trigger: options.container.querySelector('.' + this.config.triggerClass),
      type: options.container.querySelector('.' + this.config.typeClass),
      name: options.container.querySelector('.' + this.config.nameClass),
      excerpt: options.container.querySelector('.' + this.config.excerptClass),
      keywords: options.container.querySelector(
        '.' + this.config.keywordsClass
      ),
      link: options.container.querySelector('.' + this.config.linkClass),
      close: options.container.querySelector('.' + this.config.closeModalClass),
      next: {
        trigger: options.container.querySelector(
          '.' + this.config.nextTriggerClass
        ),
        name: options.container.querySelector('.' + this.config.nextNameClass),
      },
    });

    this.projectsSample = window.projectsSample;
    this.currentIndex = null;

    /*
      Define callback timing in depends of SCSS durations
      @see CSS class 'projects-sample-modal__overlays' in /layout/project-sample.scss
     */
    for (var i = 0; i < this.projectsSample.length; i += 1) {
      if (i === 0) {
        this.config.endOfTransitions +=
          this.config.transitionDuration + this.config.transitionDelay;
      } else {
        this.config.endOfTransitions +=
          this.config.transitionDuration +
          ((i * 4) / 5) * this.config.transitionDelay;
      }
    }

    if (this.modalElements.close !== null) {
      this.modalElements.close.addEventListener(
        'click',
        this.onClose.bind(this)
      );
    }

    if (this.modalElements.next.trigger !== null) {
      this.modalElements.next.trigger.addEventListener(
        'click',
        this.onNext.bind(this)
      );
    }

    var triggerElements = options.container.querySelectorAll(
      '.' + this.config.triggerClass
    );

    for (var j = 0; j < triggerElements.length; j += 1) {
      triggerElements[j].addEventListener('click', this.onOpen.bind(this));
    }

    return this;
  }

  /**
   * On click on next triggers
   * @return {Boolean|Function} False on error, showModal() on success
   */
  ProjectsSample.prototype.onNext = function() {
    var self = this;
    var next =
      this.currentIndex + 1 < this.projectsSample.length
        ? this.currentIndex + 1
        : 0;

    this.modalElements.modal.classList.add(this.config.changeModalClass);

    return setTimeout(function() {
      var data = self.updateModalData(next);

      if (data === false) {
        return false;
      }

      self.modalElements.modal.classList.remove(self.config.changeModalClass);

      return self.showModal();
    }, this.config.endOfTransitions);
  };

  /**
   * On click on open triggers
   * @param  {Object} event
   * @return {Function} showModal()
   */
  ProjectsSample.prototype.onOpen = function(event) {
    event.preventDefault();

    var triggeredElement = event.currentTarget;
    var currentIndex = triggeredElement.dataset.index;

    var data = this.updateModalData(currentIndex);

    if (data === false) {
      return false;
    }

    return this.showModal();
  };

  /**
   * On click on close triggers
   * @return {Function} hideModal()
   */
  ProjectsSample.prototype.onClose = function() {
    return this.hideModal();
  };

  /**
   * Update modal data
   * @param  {Int} index Target item index
   * @return {Boolean|Object} False on error, data on success
   */
  ProjectsSample.prototype.updateModalData = function(index) {
    this.currentIndex = parseInt(index, 10);

    var data = this.projectsSample[this.currentIndex];
    if (data === undefined) {
      return false;
    }

    // Update data - Start
    if (data.type !== undefined) {
      this.modalElements.type.innerHTML = data.type;
    }

    if (data.excerpt !== undefined) {
      this.modalElements.excerpt.innerHTML = data.excerpt;
    }

    if (data.link !== undefined) {
      this.modalElements.link.href = data.link;
    }

    if (data.keywords !== undefined) {
      this.modalElements.keywords.innerHTML = data.keywords;
    }

    if (data.name !== undefined) {
      this.modalElements.name.innerHTML = data.name;
    }

    if (data.image !== undefined) {
      this.modalElements.body.style.backgroundImage = 'url(' + data.image + ')';
    }
    // Update data - End

    // Update next data - Start
    var next =
      this.projectsSample[this.currentIndex + 1] !== undefined
        ? this.projectsSample[this.currentIndex + 1]
        : this.projectsSample[0];

    this.modalElements.next.name.innerHTML = next.name;
    this.modalElements.next.trigger.style.backgroundImage =
      'url(' + next.thumbnail + ')';
    // Update next data - End

    return data;
  };

  /**
   * Show modal
   * @return {Boolean} Class added success
   */
  ProjectsSample.prototype.showModal = function() {
    return this.modalElements.modal.classList.add(this.config.activeModalClass);
  };

  /**
   * Hide modal
   * @return {Boolean} Class removed success
   */
  ProjectsSample.prototype.hideModal = function() {
    return this.modalElements.modal.classList.remove(
      this.config.activeModalClass
    );
  };

  window.addEventListener('load', function() {
    var projectsSampleElements = document.querySelectorAll(
      '.js-projects-sample'
    );
    var projectsSamples = [];

    for (var i = 0; i < projectsSampleElements.length; i += 1) {
      projectsSamples[i] = new ProjectsSample({
        container: projectsSampleElements[i],
      });
    }

    APP.store.projectsSample = projectsSamples;
  });
})(APP, window);
